<template>
  <div class="main-contents code">
	  <div class="tit">평가항목 관리</div>
	  <div class="search-box">
		  <SelectComp cdId="PRO109" @change="changeItem()" v-model="srchFilter.evaluatorDivCd" :isAll="true" title="평가자구분"/>
		  <nbsp/>
		  <SelectComp cdId="PRO110" :filter="filterDiv" v-model="srchFilter.evalItemDivCd" :isAll="true" title="평가항목구분"/>
		  <nbsp/>
		  <img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getEvalItemList('1')"/>
	  </div>
	  <div class="Board type3">
		  <table class="Board_type3 admin">
			  <colgroup>
			  	<col width="4%">
				<col width="7%">
				<col width="11%">
				<col width="14%">
				<col width="54%">
				<col width="10%">
			</colgroup>
			<thead>
				<tr>
					<th>NO</th>
					<th>평가자구분</th>
					<th>평가항목구분</th>
					<th>평가항목</th>
					<th>평가항목내용</th>
					<th>항목관리</th>
				</tr>
			</thead>
			<tbody v-if="evalItemList.length > 0">
				<tr v-for="(item,rowIdx) in evalItemList" :key="rowIdx">
					<td class="score">{{(pageInfo.pageUnit * (pageInfo.pageIndex-1)) + rowIdx+1}}</td>
					<td class="score"><SelectComp type="text" :value="item.evaluatorDivCd" cdId="PRO109"/></td>
					<td class="score"><SelectComp type="text" :value="item.evalItemDivCd" cdId="PRO110"/></td>
					<td class="score"><SelectComp type="text" :value="item.evalItemCd" cdId="PRO111"/></td>
					<td class="score">{{item.evalItemCont}}</td>
					<td class="score btn" @click="viewEvalItem(item)"><div class="btn">항목관리 &#9654;</div></td>
				</tr>
			</tbody>
			<tbody v-else>
			<tr>
				<td colspan="6" class="none">등록된 평가항목이 없습니다!</td>
			</tr>
			</tbody>
		  </table>
	  </div>
	  <!-- 페이징부분 -->
      <pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
  </div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';

export default {
	data(){
		return{
			srchFilter:{
				pageIndex: 1,
				evaluatorDivCd : '',
				evalItemDivCd : ''
			},
			evalItemList : [],
			pageInfo : {},
			//evalItemCd : ''
			filterDiv : ''
		}
	},
	components : {
		pagingComp
	},

	mounted(){
		var keys = Object.keys(this.srchFilter);
		for(var key in this.$route.params){
			if(keys.indexOf(key) >= 0){
				this.srchFilter[key] = this.$route.params[key];
			}
		}

		this.getEvalItemList()
	},

	methods:{
		//평가항목 리스트 조회
		getEvalItemList(div){
			var param = this.srchFilter;
			param.pageUnit = '25';
			param.pageSize = '10';
			if(div) param.pageIndex = 1;

			this.$.httpPost('/api/main/adm/eval/getEvalItemList',param)
				.then(res=>{
					this.evalItemList = res.data.evalItemList;
					this.pageInfo = res.data.pageInfo;
				}).catch(this.$.httpErrorCommon);
		},

		//페이징 이동
   		 goPage(page) {
			this.srchFilter.pageIndex = page;
			this.getEvalItemList();
		},

		viewEvalItem(item){
			//var evalItemSeq = row.evalItemSeq;
			var params = {
				evalItemSeq : item.evalItemSeq,
				caller : {
					name : this.$route.name,
					params : this.srchFilter
				}
			}

			this.$router.push({name: 'MAN935M02', params });
		},

		changeItem(){
			// console.log('aaa',this.srchFilter.evaluatorDivCd);
			if(this.srchFilter.evaluatorDivCd == '01'){
				this.filterDiv = '0';
			}else if(this.srchFilter.evaluatorDivCd == '02'){
				this.filterDiv = '1';
			}else{
				this.filterDiv = this.evaluatorDivCd;
			}
			
		}
	}
}
</script>

<style>

</style>